@import '~antd/dist/antd.less';

@import './antdThemeConfig.less';
@import './fontsConnection.less';

body {
  margin: 0;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  box-shadow: transparent;
}
*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: @gray-300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

//TABS
.ant-tabs-top > .ant-tabs-nav {
  color: @gray-500;
  margin: 0 0 26px 0;
  @media (max-width: 768px) {
    margin: 0 0 32px 0;
  }
}

//NOTIFICATIONS
.notification {
  width: 812px;
  padding: 16px;
  box-shadow: none;
  border-radius: 8px;
  @media (max-width: 1024px) {
    width: 512px;
  }
  @media (max-width: 768px) {
    width: 384px;
  }
  & svg {
    width: 14px;
    height: 14px;
  }
  & .ant-notification-notice-icon {
    margin: 0;
    line-height: 0;
  }
  & .ant-notification-notice-message {
    max-width: calc(100% - 24px);
    margin: 0 0 0 24px;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
    & span {
      font-weight: 800;
    }
  }
  & .ant-notification-notice-with-icon {
    display: flex;
    align-items: center;
  }
  &.success {
    background-color: @green-50;
    border: 1px solid @green-500;
    & svg {
      color: @green-900;
    }
    & .ant-notification-notice-message {
      color: @green-900;
    }
    & .ant-notification-notice-description {
      display: none;
    }
  }
  &.error {
    background-color: @red-50;
    border: 1px solid @red-200;
    & svg {
      color: @red-500;
    }
    & .ant-notification-notice-message {
      color: @red-500;
    }
    & .ant-notification-notice-description {
      display: none;
    }
  }
  &.info {
    background-color: @blue-50;
    border: 1px solid @blue-200;
    & svg {
      color: @blue-500;
    }
    & .ant-notification-notice-message {
      color: @blue-500;
    }
    & .ant-notification-notice-description {
      display: none;
    }
  }
}

//BUTTONS
.ant-btn {
  font-family: Poppins, sans-serif;
  &.ant-btn-primary:hover.ant-btn:not([disabled]):hover {
    background: @blue-600;
    border: 1px solid @blue-600;
  }
  &.ant-btn-default:hover {
    border-color: @blue-600;
    color: @blue-600;
  }
  &.ant-btn-ghost:not([disabled]) {
    color: @gray-700;
    border-color: @gray-200;
    border-radius: 8px;
    font-family: Manrope, sans-serif;
    box-shadow: none;
    &:hover.ant-btn:not([disabled]):hover,
    &:focus.ant-btn:not([disabled]):focus {
      border-color: @gray-700;
      color: @gray-700;
    }
  }
}

//INPUTS
.ant-input {
  font-size: 16px;
  font-weight: 500;
  &[disabled] {
    color: @gray-600;
  }
}

//DATE PICKER
.ant-picker-input > input[disabled] {
  color: @gray-600;
}
.ant-picker-input > input::placeholder,
.ant-picker-input-placeholder > input {
  color: @gray-900;
}

//SELECTOR
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selection-placeholder {
  color: @gray-600;
}

// TABLE
.ant-table-wrapper {
  padding-bottom: 20px;
  .ant-table {
    letter-spacing: 0.2px;
    & table {
      border-width: 1px 1px 0 1px;
      border-style: solid;
      border-color: @gray-300;
      border-radius: 4px 4px 0 0;
    }
    & .ant-table-thead > tr > th {
      font-size: 16px;
      font-weight: 800;
      border-bottom-width: 2px;
    }
    & .ant-table-tbody > tr > td {
      border-bottom-width: 2px;
    }
    & .ant-table-row,
    & .ant-table-thead > tr {
      height: 55px;
    }
    & .ant-table-placeholder {
      display: none;
    }
    & .ant-table-column-sorters {
      display: block;
    }
    & .ant-table-column-sorter {
      margin-left: 10px;
      vertical-align: middle;
    }
    & ::-webkit-scrollbar {
      background-color: @gray-50;
    }
  }
}
.ant-table-pagination {
  margin-bottom: 0;
}
.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100%;
}

// SELECT OPTION
.ant-select-dropdown {
  font-feature-settings: normal;
  .ant-select-item {
    font-weight: 500;
  }
  .ant-select-item-option-active {
    background-color: @gray-100;
  }
}

// RANGE DATE PICKER
.ant-picker-dropdown {
  .ant-picker-content th,
  .ant-picker-content td {
    font-weight: 500;
  }
}

// UPLOAD
.ant-upload-list-item-card-actions .anticon {
  color: @blue-500;
}
.ant-upload-list-item-name {
  font-family: @font-family;
  font-size: 16px;
  max-width: 114px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-upload.ant-upload-disabled .ant-btn {
  cursor: not-allowed;
}

//BADGE
.ant-badge-count {
  background: @blue-500;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  font-weight: 400;
}
